<template>
  <section>
    <!--TABLE FUEL-->
    <b-row v-if="tripStep">
      <b-col md="12">
        <div class="d-flex flex-wrap match-height">
          <div class="col-12 col-md-6 pl-0">
            <b-card class="d-flex justify-content-end">
              <ul class="list-unstyled">
                <li class="mb-2">
                  <font-awesome-icon icon="map-marked-alt" />
                  {{ $t('service.fuel.airport_of_service') }} : {{ tripStepEventName(tripStepEvent) }}
                </li>
                <li v-if="tripStepEvent" class="mb-2">
                  <feather-icon icon="CalendarIcon" />
                  {{ $moment.utc(tripStepEvent.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}
                </li>
                <li class="mb-2">
                  <font-awesome-icon icon="plane" />
                  <span v-if="organizationAircraftData.registration">{{ organizationAircraftData.registration }} / </span>
                  <span v-if="organizationAircraftData.callSign">{{ organizationAircraftData.callSign }}</span>
                </li>
                <li>
                  <font-awesome-icon icon="paper-plane" />
                  {{ $t(`service.fuel.prices.${tripStep.flightType}`) }}
                </li>
              </ul>
            </b-card>
          </div>
          <div class="col-12 col-md-6 p-0">
            <b-card>
              <validation-observer #default="{ handleSubmit }" ref="filterFuel">
                <!-- Form -->
                <b-form ref="formEventRules" class="d-flex flex-wrap" @submit.prevent="handleSubmit(onSubmit)">
                  <b-col cols="7" class="p-0">
                    <!-- FUEL TYPES -->
                    <b-form-group class="mt-1" :label="$t('aircraft.fuel_type')" label-for="fuelType">
                      <v-select
                        id="fuelType"
                        v-model="fuelType"
                        :placeholder="$t('aircraft.all_fuel_type')"
                        :options="organizationAircraftData.fuelTypes"
                        :reduce="value => value.id"
                        :get-option-label="value => enumTranslate('fuel_types', value.name)"
                      />
                    </b-form-group>

                    <!-- Field: Maximum fuel capacity -->
                    <app-input
                      id="maxFuelCapacity"
                      v-model.number="fuelVolume"
                      :label="$t('aircraft.fuel_volume')"
                      :append="$getMeasurementUnitItn('l')"
                      rules="positive"
                      step="any"
                      type="number"
                    />
                  </b-col>

                  <b-col cols="5" class="d-flex align-items-end justify-content-end p-0 my-1">
                    <b-overlay :show="loading">
                      <b-button @click="search()">
                        <feather-icon icon="SearchIcon" />
                        {{ $t('action.search') }}
                      </b-button>
                    </b-overlay>
                  </b-col>
                </b-form>
              </validation-observer>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { fetchOrganizationAircraftRequest } from '@/request/globalApi/requests/organizationAircraftRequests'
import EnumTranslationHelper from '@/helpers/enumTranslation'
import TripHelper from '@/helpers/trip'
import { mapGetters } from 'vuex'

export default {
  name: 'FuelSearch',
  mixins: [EnumTranslationHelper, TripHelper],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    tripStep: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      trip: {},
      time: '',
      aircraft: {},
      eventType: '',
      eventData: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
      startAt: '',
      endAt: '',
      loadProducts: false,
      fuelType: null,
      fuelTypeName: '',
      fuelVolume: 0,
      organizationAircraftData: {},
      mounted: false,
    }
  },
  computed: {
    ...mapGetters('fuelSearch', ['getFuelTypeIdByEvent', 'getVolumeByEvent', 'getFuelTypeNameByEvent']),
  },
  watch: {
    fuelType: {
      handler(fuelTypeId) {
        if (fuelTypeId && this.organizationAircraftData.fuelTypes) {
          const fuelTypObject = this.organizationAircraftData.fuelTypes.find(fuelType => fuelType.id === this.fuelType)
          this.fuelTypeName = fuelTypObject.name
          this.$store.commit('fuelSearch/SET_FUEL_TYPE_ID', { tripStepEventId: this.$route.params.event_id, fuelTypeId: fuelTypObject.id })
          this.$store.commit('fuelSearch/SET_FUEL_TYPE_NAME', { tripStepEventId: this.$route.params.event_id, fuelTypeName: fuelTypObject.name })
        } else if (this.organizationAircraftData.fuelTypes) {
          this.$store.commit('fuelSearch/SET_FUEL_TYPE_ID', { tripStepEventId: this.$route.params.event_id, fuelTypeId: null })
          this.$store.commit('fuelSearch/SET_FUEL_TYPE_NAME', { tripStepEventId: this.$route.params.event_id, fuelTypeName: '' })
        }
      },
    },
    fuelVolume: {
      handler(fuelVolume) {
        if (fuelVolume) {
          this.$store.commit('fuelSearch/SET_VOLUME', { tripStepEventId: this.$route.params.event_id, volume: fuelVolume })
        } else if (this.mounted) {
          this.$store.commit('fuelSearch/SET_VOLUME', { tripStepEventId: this.$route.params.event_id, volume: 0 })
        }
      },
    },
  },

  beforeMount() {
    this.fuelType = this.getFuelTypeIdByEvent(this.$route.params.event_id)
    this.fuelTypeName = this.getFuelTypeNameByEvent(this.$route.params.event_id)
    this.fuelVolume = this.getVolumeByEvent(this.$route.params.event_id)
    this.mounted = true
  },

  mounted() {
    this.APIFetchOrganizationAircraft()
    if (this.$refs.filterFuel && this.fuelVolume !== null && this.fuelType !== null) {
      this.search()
    }
  },
  methods: {
    // Fetch an organization aircraft
    async APIFetchOrganizationAircraft() {
      this.loadProducts = true
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })

      await fetchOrganizationAircraftRequest(trip.organization.id, trip.organizationAircraft.id).then(response => {
        const { organizationAircraft } = response.data

        this.organizationAircraftData = organizationAircraft

        this.$emit('show-loading-search', false)
      })
    },
    async search() {
      await this.$refs.filterFuel.validate().then(success => {
        if (success === true) {
          this.$emit('fuel-type', this.fuelType)
          this.$emit('fuel-type-name', this.fuelTypeName)
          this.$emit('fuel-volume', this.fuelVolume)
          this.$emit('click:search-fuel')
        }
      })
    },
  },
}
</script>
