<template>
  <section>
    <!--HEADER-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <!--ACTION-->
    <section class="d-flex justify-content-end">
      <b-button variant="primary" :to="{ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } }">
        <font-awesome-icon icon="stream" class="mr-50" />
        {{ $t('trip.return_to_timeline') }}
      </b-button>
    </section>

    <b-overlay :show="showLoadingSearch" class="mt-2" spinner-medium>
      <fuel-search
        v-if="tripStep && tripStep.id"
        :loading="tripStepLoading"
        :trip-step="tripStep"
        :trip-step-event="tripStepEvent"
        @show-loading-search="val => (showLoadingSearch = val)"
        @fuel-type-name="val => (params.fuelTypeName = val)"
        @fuel-type="val => (params.fuelType = val)"
        @fuel-volume="val => (params.fuelVolume = val)"
        @click:search-fuel="searchFuel()"
      />
    </b-overlay>

    <b-overlay :show="showLoading" spinner-medium>
      <!--TABLE FUEL-->
      <b-row>
        <b-col md="12">
          <fuel-provider
            :fuel-volume="params.fuelVolume"
            :fuel-type-name="params.fuelTypeName"
            :provider-list="providerList"
            :trip-data="tripData"
            :trip-step-event="tripStepEvent"
            :trip-step="tripStep"
            :page="params.page"
            :total-items="totalItems"
            @refetch-data="params.page = $event; searchFuel()"
          />
        </b-col>
      </b-row>
    </b-overlay>

  </section>
</template>
<script>

import TripView from '@/views/trip/trip-view/TripView.vue'
import FuelProvider from '@/views/provider/fuel/FuelProvider.vue'
import FuelSearch from '@/views/provider/fuel/FuelSearch.vue'
import { fetchFuelVariantPriceRequest } from '@/request/globalApi/requests/apiRequests'

export default {
  name: 'ProviderFuel',

  components: {
    TripView,
    FuelProvider,
    FuelSearch,
  },
  data() {
    return {
      tripData: {},
      tripStep: null,
      tripStepEvent: null,
      tripLeg: null,
      legType: null,
      tripStepLoading: true,
      showLoadingSearch: true,
      providerList: null,
      showLoading: false,
      totalItems: 0,
      params: {
        fuelType: '',
        fuelVolume: 0,
        page: 1,
        numberOfItemsPerPage: 5,
      },
    }
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)

      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })

      if (trip) {
        this.tripData = trip

        trip.tripSteps.forEach(tripStep => {
          if (tripStep.className === 'TripFlight') {
            if (!this.getCurrentTripStep(tripStep, tripStep.flightDeparture)) {
              this.getCurrentTripStep(tripStep, tripStep.flightArrival)
            }
          }
        })
      }
      this.tripStepLoading = false
    },
    getCurrentTripStep(leg, event) {
      Object.entries(event).forEach(([key, val]) => {
        if (key === 'id' && val === parseInt(this.$route.params.event_id, 10)) {
          this.tripStep = leg
          this.tripStepEvent = event
          return true
        }
        return false
      })
    },
    searchFuel() {
      this.showLoading = true

      this.APIFetchFuel()
    },
    APIFetchFuel() {
      this.organizationsIds = []
      this.products = []
      fetchFuelVariantPriceRequest(this.tripStepEvent.id, this.params)
        .then(response => {
          const { fuelVariantPrices, totalItems } = response.data
          this.providerList = fuelVariantPrices
          this.totalItems = totalItems
        }).finally(() => {
          this.showLoading = false
        })
        .catch(() => {
          this.products = []
        })
    },
  },
}
</script>
<style scoped></style>
