var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('app-data-table',{attrs:{"items":_vm.providerList,"fields":_vm.tableColumns,"empty-text":_vm.$t('common.no_record_found'),"sort-by":_vm.sortBy,"per-page":_vm.params.numberOfItemsPerPage,"bottom-table":{ totalCount: _vm.totalItems },"page":_vm.params.page,"manual-pagination":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"pagination":function($event){_vm.params.page = $event}},scopedSlots:_vm._u([{key:"head(status)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"head(basePrice)",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('trip.fuel.base_price_et'))+" ")]},proxy:true},{key:"cell(seller)",fn:function(data){return [_c('b-avatar',{staticClass:"d-inline-block mr-2",attrs:{"src":data.item.organizationLogoUrl,"text":_vm.avatarText(data.item.organizationName),"size":"32"}}),_c('b-media',{staticClass:"d-inline-block",attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap text-underline"},[_c('b-link',{attrs:{"to":{
              name: 'provider-details',
              params: {
                provider_id: data.item.organizationId,
                trip_id: _vm.tripData.id,
                step_id: _vm.tripStep.id,
                event_id: _vm.tripStepEvent.id,
                airport_id: _vm.tripStepEvent.airport.id,
              },
              query: {
                currentNav: 'fuel'
              },
            }}},[_vm._v(" "+_vm._s(data.item.organizationName)+" ")])],1),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.flightType))])])]}},{key:"cell(supplier)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.supplier)+" ")])])]}},{key:"cell(contract)",fn:function(data){return [_c('b-badge',{staticClass:"mx-1",attrs:{"variant":("light-" + (_vm.resolveStatusVariant(data.item.contract)))}},[_vm._v(" "+_vm._s(data.item.contract ? _vm.$t('trip.fuel.contract') : _vm.$t('trip.fuel.no_contract'))+" ")])]}},{key:"cell(basePrice)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('trip.fuel.base_price_et'))+" : "),(data.item.unitBasePrice)?_c('span',[_vm._v(" "+_vm._s(data.item.unitBasePrice)+" "+_vm._s(_vm.$getMeasurementUnitItn('epl')))]):(!data.item.public)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('service.fuel.prices.private_price'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('service.on_quote'))+" ")])])]}},{key:"cell(fuelType)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[(data.item.fuelType)?_c('span',[_vm._v(" "+_vm._s(data.item.fuelType)+" ")]):_vm._e()])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mr-1",on:{"click":function($event){_vm.isContactFuelProviderSidebarActive = true
          _vm.providerSelected = data.item}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"comment-dots"}}),_vm._v(" "+_vm._s(_vm.$t('action.contact'))+" ")],1),(false && _vm.eventData.providers.some(function (provider) { return provider.provider.id === data.item.id && data.item.status === 'Pending'; }))?_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(_vm.$t('timeline.fuel.request_quotation.sent'))+" ")]):_c('b-button',{on:{"click":function($event){_vm.isRequestQuotationSidebarActive = true
          _vm.providerSelected = data.item
          _vm.fuelTypeName = data.item.fuelType}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"file-invoice-dollar"}}),_vm._v(" "+_vm._s(_vm.$t('trip.fuel.request_quotation'))+" ")],1)]}}])}),_c('sidebar-request-quotation',{attrs:{"fuel-settings":_vm.fuelSettings,"provider-fuel":_vm.providerSelected,"trip-data":_vm.tripData,"trip-step":_vm.tripStep,"trip-step-event":_vm.tripStepEvent},model:{value:(_vm.isRequestQuotationSidebarActive),callback:function ($$v) {_vm.isRequestQuotationSidebarActive=$$v},expression:"isRequestQuotationSidebarActive"}}),_c('sidebar-contact-fuel-provider',{attrs:{"provider-fuel":_vm.providerSelected},model:{value:(_vm.isContactFuelProviderSidebarActive),callback:function ($$v) {_vm.isContactFuelProviderSidebarActive=$$v},expression:"isContactFuelProviderSidebarActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }