<template>
  <b-sidebar id="sidebar-addEvent" :visible="isRequestQuotationSidebarActive" no-header @change="$emit('update:is-request-quotation-sidebar-active', $event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('trip.fuel.sidebar.request_quotation') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <div v-if="fuelProduct.sellerName" class="mx-1 p-50 mb-0 h6">
        {{ $t('service.order_request.seller_name') }} : {{ fuelProduct.sellerName }}
      </div>
      <!-- BODY -->
      <ul v-if="fuelProduct" class="mx-1 p-50 mb-0 font-small-2">

        <div class="h6"> {{ $t('service.order_request.customer_details') }} </div>
        <li v-if="fuelProduct.customerName" class="ml-1"> {{ $t('service.order_request.customer_name') }} : {{ fuelProduct.customerName }}</li>
        <li v-if="fuelProduct.customerVat" class="ml-1"> {{ $t('service.order_request.vat_number') }} : {{ fuelProduct.customerVat }}</li>
        <li v-if="fuelProduct.customerEmail" class="ml-1"> {{ $t('service.order_request.customer_email') }} : {{ fuelProduct.customerEmail }}</li>

        <!-- TO SEE LATER
        <div class="h6 mt-2"> {{ $t('service.order_request.customer_payment') }} </div>
        <li v-if="fuelProduct.paymentMethods.CARD" class="ml-1"> {{ fuelProduct.paymentMethods.CARD }}</li>
        <li v-if="fuelProduct.paymentMethods.SEPA_DEBIT" class="ml-1"> {{ fuelProduct.paymentMethods.SEPA_DEBIT }}</li>
        -->

        <div class="h6 mt-2"> {{ $t('service.order_request.aircraft_details') }} </div>
        <li v-if="fuelProduct.aircraftType" class="ml-1"> {{ $t('trip.event.aircraft_type') }} : {{ fuelProduct.aircraftType }}</li>
        <li v-if="fuelProduct.aircraftRegistration" class="ml-1"> {{ $t('service.fuel_certificate.options.aircraft_registration_option') }} : {{ fuelProduct.aircraftRegistration }}</li>
        <li v-if="fuelProduct.callSign" class="ml-1"> {{ $t('aircraft.call_sign') }} : {{ fuelProduct.callSign }}</li>
        <li v-if="fuelProduct.homeBase" class="ml-1"> {{ $t('service.order_request.home_base') }} : {{ fuelProduct.homeBase.code }} - {{ fuelProduct.homeBase.name }} </li>
        <li v-if="fuelProduct.mtow" class="ml-1"> {{ $t('service.order_request.mtow') }} : {{ fuelProduct.mtow }} {{ $getMeasurementUnitItn('kg') }}</li>

        <div class="h6 mt-2"> {{ $t('service.order_request.aircraft_schedule') }} </div>

        <li class="ml-1">{{ $t('service.order_request.airport_of_service') }} : <span v-if="tripStepEvent.airport.iataCode">{{ tripStepEvent.airport.iataCode }} / </span>{{ tripStepEvent.airport.icaoCode }}</li>
        <li class="ml-1">
          {{ $t('common.from') }} : {{ tripStep.flightDeparture.airport.icaoCode }}
          / {{ $t('common.to') }} : {{ tripStep.flightArrival.airport.icaoCode }}
        </li>
        <li class="ml-1">
          {{ $t('trip.event.etd') }} : {{ $moment.utc(tripStep.flightDeparture.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}
          / {{ $t('trip.event.eta') }} : {{ $moment.utc(tripStep.flightArrival.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</li>
        <li class="ml-1"> {{ $t('trip.event.flight_type') }} : {{ tripStep.flightType|enumTranslate('flight_type') }}</li>

      </ul>
      <div class="p-2">
        <div>
          <span class="font-weight-bold">{{ $t('timeline.fuel.sidebar.fuel') }}</span> :
          <span>
            {{ fuelSettings.fuelTypeName }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold">{{ $t('timeline.fuel.sidebar.price') }}</span> :
          <span v-if="fuelProduct.unitBasePrice"> {{ fuelProduct.unitBasePrice }} {{ $getMeasurementUnitItn('epl') }}</span>
          <span v-else> {{ $t('service.on_quote') }}</span>
        </div>
        <div v-if="fuelProduct.supplier">
          <span class="font-weight-bold">{{ $t('service.order_request.supplier') }}</span> :
          <span> {{ fuelProduct.supplier }}</span>
        </div>

        <validation-observer ref="requestQuotationRules" tag="form">
          <b-form-group label-for="dateFuelRequested">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold">{{ $t('service.order_request.date_fuel_requested') }}</span> :
              <b-col class="p-0 ml-2" cols="6">
                <flat-pickr
                  id="dateFuelRequested"
                  v-model="fuelSettings.dateFuelRequested"
                  :config="{
                    enableTime: true,
                    altInput: true,
                    altFormat: 'F j, Y H:i',
                    dateFormat: 'Y-m-dTH:i',
                    localize: $i18n.locale,
                    time_24hr: true,
                  }"
                  class="form-control"
                />
              </b-col>
            </div>
          </b-form-group>

          <b-form-group label-for="fuelVolume">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold">{{ $t('timeline.fuel.sidebar.fuel_quantity') }}</span> :
              <b-col class="p-0 ml-2" cols="6">
                <!-- Field: Maximum fuel capacity -->
                <app-input
                  id="maxFuelCapacity"
                  v-model.number="fuelSettings.quantity"
                  :append="$getMeasurementUnitItn('l')"
                  rules="positive"
                  step="any"
                  type="number"
                />
              </b-col>
            </div>
          </b-form-group>

          <b-form-group label-for="fbo">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold">{{ $t('common.fbo') }}</span> :
              <app-input
                id="fbo"
                v-model="fuelSettings.fbo"
                class="p-0 ml-2"
              />
            </div>
          </b-form-group>

          <b-form-group :label="$t('common.message')" class="mt-1" label-for="fuelMessage">
            <b-form-textarea id="serviceName" v-model="fuelSettings.message" required rows="5" />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="mr-50" variant="outline-secondary" @click="hide">
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
            <b-button type="submit" @click.prevent="validationForm">
              <font-awesome-icon class="mr-50" icon="file-invoice-dollar" />
              {{ $t('trip.fuel.sidebar.request_quotation') }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  fetchFuelVariantPriceDetailRequest,
  patchFuelVariantPriceQuotationRequest,
} from '@/request/globalApi/requests/apiRequests'

export default {
  name: 'SidebarRequestQuotation',
  model: {
    prop: 'isRequestQuotationSidebarActive',
    event: 'update:is-request-quotation-sidebar-active',
  },
  props: {
    isRequestQuotationSidebarActive: {
      type: Boolean,
      default: false,
    },
    fuelSettings: {
      type: Object,
      default: () => ({}),
    },
    providerFuel: {
      type: Object,
      default: () => ({}),
    },
    providerSelected: {
      type: Object,
      default: () => ({}),
    },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    tripStep: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fuelProduct: {},
    }
  },
  watch: {
    providerFuel: {
      handler() {
        this.APIFetchFuelDetail()
      },
    },
  },
  methods: {
    validationForm() {
      this.$refs.requestQuotationRules.validate().then(result => {
        if (result) {
          patchFuelVariantPriceQuotationRequest(this.$route.params.event_id, this.providerFuel.fuelVariantPriceId, this.fuelSettings)
            .then(() => {
              this.$emit('update:is-request-quotation-sidebar-active', false)
            })
        }
      })
    },
    onSubmit() {
      const params = {
        tripId: this.$route.params.trip_id,
        eventId: this.$route.params.event_id,
        products: [this.providerFuel],
        provider: this.providerFuel,
      }
      this.$store.dispatch('trip/addProductsInEvent', params).then(() => {
        this.alert(this.$t('api.alert.form.saved_successfully'))
        this.$emit('input', false)
      })
    },
    APIFetchFuelDetail() {
      this.fuelProduct = {}

      fetchFuelVariantPriceDetailRequest(this.$route.params.event_id, this.providerFuel.id, this.params)
        .then(response => {
          this.fuelProduct = response.data
        })
        .catch(() => {
          this.fuelProduct = {}
        })
    },
  },
}
</script>
